import React, { useState } from 'react';
import Login from './pages/Login/Login';
import { GlobalStyle, XContainer, Theme, XLayout } from './styles/GlobalStyle';
import { BrowserRouter, Routes, Route, Outlet } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import Page404 from './pages/Page404';
import PrivateRoute from './components/PrivateRoute';
import { Layout } from 'antd';
import 'bootstrap/dist/css/bootstrap.min.css';
import Dashboard from './pages/Dashboard/Dashboard';
import ForgotEmailPassword from './pages/ForgotEmailPassword/ForgotEmailPassword';
import VerifyOtp from './pages/VerifyOtp/VerifyOtp';
import ResetPassword from './pages/ResetPassword/ResetPassword';
import CaseForm from './pages/CaseForm/CaseForm';
import Manage from './pages/Manage/Manage';
import Profile from './pages/Profile/Profile';
import CaseClosure from './pages/CaseClosure/CaseClosure';
import CasePreparation from './pages/CasePreparation/CasePreparation';
import AnalyseCoverage from './pages/AnalyseCoverage/AnalyseCoverage';
import ChangePassword from './pages/ChangePassword/ChangePassword';
import CaseManagement from './pages/Manage/CaseManagement';
import CaseAnalysis from './pages/CaseAnalysis/CaseAnalysis';
import CaseAnalysisView from './pages/CaseAnalysis/CaseAnalysisView';
import CaseView from './pages/CaseView/CaseView';
import CaseFilings from './pages/CaseFilings/CaseFilings';
import CaseLiterature from './pages/CaseLiterature/CaseLiterature';
import FeedBackView from './pages/FeedBackAnalysis/FeedBackView';
import FeedBackList from './pages/FeedBackAnalysis/FeedBackList';
import Sidebar from './components/Sidebar/Sidebar';
import AppealLetterView from './pages/AppealLetter/AppealLetterView';
import AppealLetter from './pages/AppealLetter/AppealLetter';
import InsuranceCompanyList from './pages/InsuranceCompany/InsuranceCompanyList';
import HospitalList from './pages/Hospital/HospitalList';
import HospitalForm from './pages/Hospital/HospitalForm';
import ScrollToTop from './components/ScrollToTop';
import Regions from './pages/Regions/Regions';
import Organization from './pages/Organization/Organization';
import OrganizationForm from './pages/Organization/OrganizationForm';
import ModuleForm from './pages/ModuleForm/ModuleForm';
import AddEditUser from './pages/Manage/AddEditUser';
import CaseFilingManagement from './pages/CaseFilingManagement/CaseFilingManagement';
import StandardAppealTAT from './pages/MAPlansAppealsProcessing/StandardAppealTAT';
import ManageMAPlansAppeal from './pages/MAPlansAppealsProcessing/Manage';
import PayerConfiguration from './pages/PayerConfiguration/PayerConfiguration';
import PayerConfigurationForm from './pages/PayerConfiguration/PayerConfigurationForm';
import Reimbursements from './pages/Reimbursements/Reimbursements';
import ReimbursementsView from './pages/Reimbursements/ReimbursementsView';
import Consent from './pages/Consent/Consent';
import ManageAppealLetters from './pages/AssignAppealLetter/Manage';
import CodingTeamAssign from './pages/AssignAppealLetter/CodingTeamAssign';
import MDAdvisorAssign from './pages/AssignAppealLetter/MDAdvisorAssign';
import AppealLetterPreview from './pages/CaseFilings/AppealLetterPreview';
import WebviewTest from './pages/WebviewTest/WebviewTest';
import InsuranceCompanyForm from './pages/InsuranceCompany/InsuranceCompanyForm';
import './App.css';
import Aipa from './pages/Aipa/Aipa';
import NotificationManagement from './pages/Notification/NotificationManagement';
import NewAipa from './pages/AipaNew/NewAipa';

const { Header, Content, Footer } = Layout;

function App(props) {
	const [collapsed, setCollapsed] = useState(false);
	// Functions
	const toggle = (data) => {
		setCollapsed(data);
	};

	return (
		<ThemeProvider theme={Theme}>
			<GlobalStyle />
			<BrowserRouter>
				<ScrollToTop />
				<XLayout>
					<Routes>
						<Route path="/login" element={<Login />}></Route>
						<Route
							path="/forgot-email-password"
							element={<ForgotEmailPassword />}
						></Route>
						<Route path="/consent" element={<Consent />}></Route>
						<Route
							path="/reset-password"
							element={<ResetPassword />}
						></Route>
						<Route
							path="/verify-otp"
							element={<VerifyOtp />}
						></Route>
						<Route
							path="/webview-test"
							element={<WebviewTest />}
						></Route>
						<Route
							path="/"
							element={
								<Layout hasSider>
									<Sidebar
										collapsed={collapsed}
										toggle={(data) => toggle(data)}
									/>
									<Layout
										style={{
											marginLeft: collapsed ? 0 : 60,
											marginTop: collapsed ? 0 : 20,
										}}
										className="p-0"
									>
										<Content className="mainContent">
											<div
												style={{
													marginLeft: '0px',
												}}
											>
												<Outlet />
											</div>
										</Content>
									</Layout>
								</Layout>
							}
						>
							<Route
								exact
								path="/"
								element={
									<PrivateRoute>
										<Dashboard />
									</PrivateRoute>
								}
							/>
							<Route
								exact
								path="/case/add"
								element={
									<PrivateRoute>
										<CaseForm />
									</PrivateRoute>
								}
							></Route>
							<Route
								exact
								path="/case/edit/:id"
								element={
									<PrivateRoute>
										<CaseForm />
									</PrivateRoute>
								}
							></Route>
							<Route
								exact
								path="/case/view/:id"
								element={
									<PrivateRoute>
										<CaseView />
									</PrivateRoute>
								}
							></Route>
							<Route
								exact
								path="/case/filings/:id"
								element={
									<PrivateRoute>
										<CaseFilings />
									</PrivateRoute>
								}
							></Route>
							<Route
								exact
								path="/case/filings/preview/:id"
								element={
									<PrivateRoute>
										<AppealLetterPreview />
									</PrivateRoute>
								}
							></Route>
							<Route
								exact
								path="/case/ma/:id"
								element={
									<PrivateRoute>
										<CaseFilings />
									</PrivateRoute>
								}
							></Route>
							<Route
								exact
								path="/feedback"
								element={<FeedBackList />}
							></Route>
							<Route
								exact
								path="/feedback/view/:id"
								element={<FeedBackView />}
							></Route>
							<Route
								exact
								path="/case-management"
								element={
									<PrivateRoute>
										<CaseManagement />
									</PrivateRoute>
								}
							></Route>
							<Route
								exact
								path="/user-role-management"
								element={
									<PrivateRoute>
										<Manage />
									</PrivateRoute>
								}
							></Route>
							<Route
								exact
								path="/user-role-management/user/:id"
								element={
									<PrivateRoute>
										<AddEditUser />
									</PrivateRoute>
								}
							></Route>
							<Route
								exact
								path="/user-role-management/user"
								element={
									<PrivateRoute>
										<AddEditUser />
									</PrivateRoute>
								}
							></Route>

							<Route
								exact
								path="/case-filing-management"
								element={
									<PrivateRoute>
										<CaseFilingManagement />
									</PrivateRoute>
								}
							></Route>
							<Route
								exact
								path="/md-advisor"
								element={
									<PrivateRoute>
										<CaseFilingManagement />
									</PrivateRoute>
								}
							></Route>
							<Route
								exact
								path="/coding-team"
								element={
									<PrivateRoute>
										<CaseFilingManagement />
									</PrivateRoute>
								}
							></Route>
							<Route
								exact
								path="/appeal-letter-assignment"
								element={
									<PrivateRoute>
										<ManageAppealLetters />
									</PrivateRoute>
								}
							></Route>
							<Route
								exact
								path="/appeal-letter-assignment/assign-coding-team/:id"
								element={
									<PrivateRoute>
										<CodingTeamAssign />
									</PrivateRoute>
								}
							></Route>
							<Route
								exact
								path="/appeal-letter-assignment/assign-md-advisor/:id"
								element={
									<PrivateRoute>
										<MDAdvisorAssign />
									</PrivateRoute>
								}
							></Route>
							<Route
								exact
								path="/standard-appeal-tat"
								element={
									<PrivateRoute>
										<ManageMAPlansAppeal />
									</PrivateRoute>
								}
							></Route>
							<Route
								exact
								path="/case-closure"
								element={
									<PrivateRoute>
										<CaseClosure />
									</PrivateRoute>
								}
							></Route>
							<Route
								exact
								path="/case-literature"
								element={
									<PrivateRoute>
										<CaseLiterature />
									</PrivateRoute>
								}
							></Route>
							<Route
								exact
								path="/case-analysis"
								element={
									<PrivateRoute>
										<CaseAnalysis />
									</PrivateRoute>
								}
							></Route>
							<Route
								exact
								path="/case-analysis/:id"
								element={
									<PrivateRoute>
										<CaseAnalysisView />
									</PrivateRoute>
								}
							></Route>
							<Route
								exact
								path="/appeal-letter"
								element={
									<PrivateRoute>
										<AppealLetter />
									</PrivateRoute>
								}
							></Route>
							<Route
								exact
								path="/appeal-letter/:id"
								element={
									<PrivateRoute>
										<AppealLetterView />
									</PrivateRoute>
								}
							></Route>
							<Route
								exact
								path="/insurance-company"
								element={
									<PrivateRoute>
										<InsuranceCompanyList />
									</PrivateRoute>
								}
							></Route>
							<Route
								exact
								path="/insurance-company/add"
								element={
									<PrivateRoute>
										<InsuranceCompanyForm />
									</PrivateRoute>
								}
							></Route>
							<Route
								exact
								path="/insurance-company/edit/:id"
								element={
									<PrivateRoute>
										<InsuranceCompanyForm />
									</PrivateRoute>
								}
							></Route>
							<Route
								exact
								path="/hospital"
								element={
									<PrivateRoute>
										<HospitalList />
									</PrivateRoute>
								}
							></Route>
							<Route
								exact
								path="/hospital/add"
								element={
									<PrivateRoute>
										<HospitalForm />
									</PrivateRoute>
								}
							></Route>
							<Route
								exact
								path="/hospital/edit/:id"
								element={
									<PrivateRoute>
										<HospitalForm />
									</PrivateRoute>
								}
							></Route>
							<Route
								exact
								path="/module/add"
								element={
									<PrivateRoute>
										<ModuleForm />
									</PrivateRoute>
								}
							></Route>
							<Route
								exact
								path="/module/edit/:id"
								element={
									<PrivateRoute>
										<ModuleForm />
									</PrivateRoute>
								}
							></Route>
							<Route
								exact
								path="/open-analysis"
								element={
									<PrivateRoute>
										<AnalyseCoverage />
									</PrivateRoute>
								}
							></Route>
							<Route
								exact
								path="/aipa"
								element={
									<PrivateRoute>
										<NewAipa />
									</PrivateRoute>
								}
							></Route>
							{/* <Route
								exact
								path="/new-aipa"
								element={
									<PrivateRoute>
										<NewAipa />
									</PrivateRoute>
								}
							></Route> */}
							<Route
								exact
								path="/case-preparation"
								element={<CasePreparation />}
							></Route>
							<Route
								exact
								path="/profile"
								element={
									<PrivateRoute>
										<Profile />
									</PrivateRoute>
								}
							></Route>
							<Route
								exact
								path="/change-password"
								element={
									<PrivateRoute>
										<ChangePassword />
									</PrivateRoute>
								}
							></Route>
							<Route
								exact
								path="/regions"
								element={
									<PrivateRoute>
										<Regions />
									</PrivateRoute>
								}
							></Route>
							<Route
								exact
								path="/organization"
								element={
									<PrivateRoute>
										<Organization />
									</PrivateRoute>
								}
							></Route>
							<Route
								exact
								path="/organization/add"
								element={
									<PrivateRoute>
										<OrganizationForm />
									</PrivateRoute>
								}
							></Route>
							<Route
								exact
								path="/organization/edit/:id"
								element={
									<PrivateRoute>
										<OrganizationForm />
									</PrivateRoute>
								}
							></Route>
							<Route
								exact
								path="/payer-configuration"
								element={
									<PrivateRoute>
										<PayerConfiguration />
									</PrivateRoute>
								}
							></Route>
							<Route
								exact
								path="/payer-configuration/add"
								element={
									<PrivateRoute>
										<PayerConfigurationForm />
									</PrivateRoute>
								}
							></Route>
							<Route
								exact
								path="/payer-configuration/edit/:id"
								element={
									<PrivateRoute>
										<PayerConfigurationForm />
									</PrivateRoute>
								}
							></Route>
							<Route
								exact
								path="/reimbursements"
								element={
									<PrivateRoute>
										<Reimbursements />
									</PrivateRoute>
								}
							></Route>
							<Route
								exact
								path="/reimbursements/:id"
								element={
									<PrivateRoute>
										<ReimbursementsView />
									</PrivateRoute>
								}
							></Route>
							<Route
								exact
								path="/notification-management"
								element={
									<PrivateRoute>
										<NotificationManagement />
									</PrivateRoute>
								}
							></Route>
						</Route>
						<Route path="/*" element={<Page404 />} />
					</Routes>
				</XLayout>
			</BrowserRouter>
		</ThemeProvider>
	);
}

export default App;
