import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Row, Col, notification } from 'antd';
import { Logo, Title, XForm, XLottie } from '../Login/LoginStyle';
import { CenterWrapper, XPara1 } from './ForgotEmailPasswordStyle';
import { Button, Input } from 'antd';
import { useNavigate } from 'react-router-dom';
import LoginAnimation from '../../json/Login.json';
import { XContainer } from '../../styles/GlobalStyle';
import Navbar from 'react-bootstrap/Navbar';
import { handleInputChange } from '../../utils/formFunctions';
// Actions
import {
	sendPasswordResetEmailApi,
	sendPasswordResetEmailReset,
} from '../../actions/sendPasswordResetEmailAction';

const Forgot = (props) => {
	const {
		sendPasswordResetEmail,
		sendPasswordResetEmailReset,
		sendPasswordResetEmailState,
	} = props;
	const [form] = XForm.useForm();
	const [formData, setFormData] = useState({});
	const navigate = useNavigate();

	useEffect(() => {
		if (sendPasswordResetEmailState.apiState === 'success') {
			localStorage.setItem('email', formData.email);
			sendPasswordResetEmailReset();
			navigate('/verify-otp');
		} else if (sendPasswordResetEmailState.apiState === 'error') {
			notification.error({
				message: sendPasswordResetEmailState.message,
			});
			sendPasswordResetEmailReset();
		}
	}, [sendPasswordResetEmailState]);

	return (
		<XContainer fluid>
			<Row>
				<Col xs={24}>
					<Navbar.Brand href="/">
						<Logo>
							<img
								className="img-fluid"
								src="./images/logo.svg"
								alt="logo"
							/>
						</Logo>
					</Navbar.Brand>
				</Col>
			</Row>
			<CenterWrapper>
				<Row className="align-items-center">
					<Col
						xs={{ span: 24, order: 2 }}
						sm={{ span: 24, order: 2 }}
						lg={{ span: 8, order: 1 }}
					>
						<Title className="mb-2">Forgot password</Title>
						<XPara1 className="mb-4 mb-lg-5 text-left">
							Enter the email address associated with your account
							and we'll send you a link to reset your password
						</XPara1>

						<XForm
							form={form}
							name="loginForm"
							layout="vertical"
							autoComplete="off"
							onFinish={() => sendPasswordResetEmail(formData)}
						>
							<XForm.Item
								name="email"
								label="Email Address"
								rules={[
									{
										required: true,
										message: 'Please enter email address',
									},
								]}
							>
								<Input
									size="large"
									name="email"
									placeholder="Enter Email Address"
									onChange={(e) =>
										handleInputChange(
											e,
											formData,
											setFormData,
										)
									}
								/>
							</XForm.Item>

							<XForm.Item className="mt-5">
								<Button htmlType="submit">
									Send OTP on Email
								</Button>
							</XForm.Item>
						</XForm>
					</Col>
					<Col
						xs={{ span: 24, order: 1 }}
						sm={{ span: 24, order: 1 }}
						lg={{ span: 10, offset: 6, order: 2 }}
						className="text-center"
					>
						<XLottie animationData={LoginAnimation} loop={true} />
					</Col>
				</Row>
			</CenterWrapper>
		</XContainer>
	);
};

const mapStateToProps = (state) => ({
	sendPasswordResetEmailState: state.sendPasswordResetEmail,
});

const mapDispatchToProps = (dispatch) => ({
	sendPasswordResetEmail: (params) =>
		dispatch(sendPasswordResetEmailApi(params)),
	sendPasswordResetEmailReset: () => dispatch(sendPasswordResetEmailReset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Forgot);
