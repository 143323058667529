import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { Link } from 'react-router-dom';
import {
	XNavbar,
	XSpace,
	XDropdown,
	XAvatar,
	XDownOutlined,
	XUpdateModal,
} from './HeaderStyle';
import { MenuOutlined, CloseOutlined } from '@ant-design/icons';
import { XContainer } from '../../styles/GlobalStyle';
// actions
import { logoutApi, logoutReset } from '../../actions/logoutAction';
import { Col, notification, Row, Skeleton } from 'antd';
import config from '../../config';
import {
	checkModulePermissions,
	getFirstAvailableRoute,
	getUserProfile,
} from '../../utils/helper';
import { SoundOutlined } from '@ant-design/icons';
import {
	getCodeUpdateDatesApi,
	getCodeUpdateDatesReset,
} from '../../actions/getCodeUpdateDatesAction';
import moment from 'moment';

const HeaderFile = (props) => {
	const {
		logout,
		logoutReset,
		logoutState,
		getCodeUpdateDates,
		getCodeUpdateDatesState,
		getCodeUpdateDatesReset,
	} = props;
	const navigate = useNavigate();
	const [hamberToggle, setHamberToggle] = useState(false);
	const [previousPath, setPreviousPath] = useState(null); // Store previous path
	const location = useLocation();
	const [updatedDatesModal, setUpdatedDatesModal] = useState(false);
	const [datesLoading, setDatesLoading] = useState(false);
	const [updatedDates, setUpdatedDates] = useState('');

	const toggleHamburger = () => {
		setHamberToggle(!hamberToggle);
	};

	const items = [
		{
			key: '1',
			label: <Link to="/profile">My Profile</Link>,
			icon: (
				<img
					className="img-fluid"
					src="/images/profile-icon.svg"
					alt=""
				/>
			),
		},
		{
			type: 'divider',
		},
		localStorage.getItem('role') === 'super-admin' && {
			key: '2',
			label: (
				<Link
					onClick={() => {
						setUpdatedDatesModal(true);
						getCodeUpdateDates(); // Fetch the update dates when the link is clicked
					}}
				>
					Policies and Codes Updated at
				</Link>
			),
			icon: <SoundOutlined style={{ fontSize: '1rem' }} />,
		},
		localStorage.getItem('role') === 'super-admin' && { type: 'divider' }, // Optional divider
		{
			key: '3',
			label: <Link to="/change-password">Reset Password</Link>,
			icon: <img className="img-fluid" src="/images/reset.svg" alt="" />,
		},
		{
			type: 'divider',
		},
		{
			key: '4',
			label: (
				<Link
					onClick={() => {
						console.log('loks');
						logout();
					}}
				>
					Log Out
				</Link>
			),
			icon: <img className="img-fluid" src="/images/logout.svg" alt="" />,
		},
	];

	// Store the current path before it changes
	useEffect(() => {
		setPreviousPath(location.pathname);
	}, [location]);

	// use effect
	useEffect(() => {
		if (logoutState.apiState === 'success') {
			localStorage.removeItem('accessToken');
			localStorage.removeItem('email');
			localStorage.removeItem('username');
			localStorage.removeItem('refreshToken');
			localStorage.removeItem('expiresAt');
			localStorage.removeItem('changePassword');
			localStorage.removeItem('profile');
			localStorage.removeItem('permissions');
			localStorage.removeItem('role');
			localStorage.removeItem('fcmToken');
			logoutReset();
			navigate('/login');
		} else if (logoutState.apiState === 'error') {
			notification.error({ message: logoutState.message });
			logoutReset();
		}
	}, [logoutState]);

	// use effect
	useEffect(() => {
		if (logoutState.apiState === 'success') {
			localStorage.removeItem('accessToken');
			localStorage.removeItem('username');
			localStorage.removeItem('refreshToken');
			localStorage.removeItem('expiresAt');
			localStorage.removeItem('profile');
			localStorage.removeItem('permissions');
			localStorage.removeItem('fcmToken');
			logoutReset();
			navigate('/login');
		} else if (logoutState.apiState === 'error') {
			notification.error({ message: logoutState.message });
			logoutReset();
		}
	}, [logoutState]);

	useEffect(() => {
		if (getCodeUpdateDatesState.apiState === 'loading') {
			setDatesLoading(true);
		}
		if (getCodeUpdateDatesState.apiState === 'success') {
			setDatesLoading(false);
			setUpdatedDates(getCodeUpdateDatesState.data);
		}
		if (getCodeUpdateDatesState.apiState === 'error') {
			setDatesLoading(false);
			notification.error({ message: getCodeUpdateDatesState.message });
		}
		getCodeUpdateDatesReset();
	}, [getCodeUpdateDatesState]);

	return (
		<div className="marginTopMobile">
			<XNavbar expand={'xl'} className="mb-3">
				<XContainer fluid>
					<Navbar.Brand
						href={
							checkModulePermissions('dashboard').authorized
								? '/'
								: getFirstAvailableRoute()?.path || '/'
						}
					>
						<img
							className="img-fluid"
							src="/images/logo.svg"
							alt=""
						/>
					</Navbar.Brand>

					<Navbar.Toggle
						onClick={toggleHamburger}
						// aria-controls={`offcanvasNavbar-expand-xl`}
					>
						{hamberToggle ? <CloseOutlined /> : <MenuOutlined />}
					</Navbar.Toggle>

					<Navbar.Offcanvas
						id={`offcanvasNavbar-expand-xl`}
						aria-labelledby={`offcanvasNavbarLabel-expand-xl`}
						placement="top"
						visible="true"
					>
						<Offcanvas.Body>
							<Nav className="justify-content-center centerMenuSpace flex-grow-1">
								{checkModulePermissions('dashboard')
									.authorized && (
									<Link
										to={'/'}
										className={
											location.pathname === '/'
												? 'nav-link active'
												: 'nav-link'
										}
									>
										Dashboard
									</Link>
								)}
								{checkModulePermissions('case-management')
									.authorized && (
									<Link
										to={'/case-management'}
										className={
											location.pathname ===
												'/case-management' ||
											location.pathname.startsWith(
												'/case/view',
											)
												? 'nav-link active'
												: 'nav-link'
										}
									>
										Case Management
									</Link>
								)}
								{checkModulePermissions('user-management')
									.authorized && (
									<Link
										to={'/user-role-management'}
										className={
											location.pathname ===
												'/user-role-management' ||
											location.pathname.startsWith(
												'/user-role-management/',
											)
												? 'nav-link active'
												: 'nav-link'
										}
									>
										User & Role Management
									</Link>
								)}
								{checkModulePermissions('open-analysis')
									.authorized && (
									<>
										{previousPath === '/open-analysis' ? (
											<a
												href="/open-analysis"
												className={
													location.pathname ===
													'/open-analysis'
														? 'nav-link active'
														: 'nav-link'
												}
											>
												Open Analysis
											</a>
										) : (
											<Link
												to={'/open-analysis'}
												className={
													location.pathname ===
													'/open-analysis'
														? 'nav-link active'
														: 'nav-link'
												}
											>
												Open Analysis
											</Link>
										)}
									</>
								)}
								{checkModulePermissions('aipa').authorized && (
									<>
										{previousPath === '/aipa' ? (
											<a
												href="/aipa"
												className={
													location.pathname ===
													'/aipa'
														? 'nav-link active'
														: 'nav-link'
												}
											>
												AIPA
											</a>
										) : (
											<Link
												to={'/aipa'}
												className={
													location.pathname ===
													'/aipa'
														? 'nav-link active'
														: 'nav-link'
												}
											>
												AIPA
											</Link>
										)}
									</>
								)}
								{checkModulePermissions('case-analysis')
									.authorized && (
									<Link
										to={'/case-analysis'}
										className={
											location.pathname.startsWith(
												'/case-analysis',
											)
												? 'nav-link active'
												: 'nav-link'
										}
									>
										Case Analysis
									</Link>
								)}
								{checkModulePermissions('case-filing')
									.authorized && (
									<Link
										to={'/case-filing-management'}
										className={
											location.pathname.startsWith(
												'/case-filing-management',
											) ||
											location.pathname.startsWith(
												'/case/filings',
											)
												? 'nav-link active'
												: 'nav-link'
										}
									>
										Case Filing
									</Link>
								)}
								{checkModulePermissions(
									'ma-plan-appeals-processing',
								).authorized && (
									<Link
										to={'/standard-appeal-tat'}
										className={
											location.pathname.startsWith(
												'/standard-appeal-tat',
											) ||
											location.pathname.startsWith(
												'/case/ma',
											)
												? 'nav-link active'
												: 'nav-link'
										}
									>
										MA Plan Appeals Processing
									</Link>
								)}
								{checkModulePermissions('case-closure')
									.authorized && (
									<Link
										to={'/case-closure'}
										className={
											location.pathname.startsWith(
												'/case-closure',
											)
												? 'nav-link active'
												: 'nav-link'
										}
									>
										Case Closure
									</Link>
								)}
								{checkModulePermissions('coding-team')
									.authorized && (
									<Link
										to={'/coding-team'}
										className={
											location.pathname.startsWith(
												'/coding-team',
											)
												? 'nav-link active'
												: 'nav-link'
										}
									>
										Coding Team
									</Link>
								)}
								{checkModulePermissions('md-advisor')
									.authorized && (
									<Link
										to={'/md-advisor'}
										className={
											location.pathname.startsWith(
												'/md-advisor',
											)
												? 'nav-link active'
												: 'nav-link'
										}
									>
										MD Advisor
									</Link>
								)}
								{checkModulePermissions(
									'appeal-letter-assignment',
								).authorized && (
									<Link
										to={'/appeal-letter-assignment'}
										className={
											location.pathname.startsWith(
												'/appeal-letter-assignment',
											)
												? 'nav-link active'
												: 'nav-link'
										}
									>
										Assign Appeal Letter
									</Link>
								)}
								{checkModulePermissions('feedback-analysis')
									.authorized && (
									<Link
										to={'/feedback'}
										className={
											location.pathname.startsWith(
												'/feedback',
											)
												? 'nav-link active'
												: 'nav-link'
										}
									>
										Feedback
									</Link>
								)}
								{checkModulePermissions('appeal-letter')
									.authorized && (
									<Link
										to={'/appeal-letter'}
										className={
											location.pathname.startsWith(
												'/appeal-letter',
											)
												? 'nav-link active'
												: 'nav-link'
										}
									>
										Appeal Letter
									</Link>
								)}
								{checkModulePermissions(
									'insurance-company-management',
								).authorized && (
									<Link
										to={'/insurance-company'}
										className={
											location.pathname.startsWith(
												'/insurance-company',
											)
												? 'nav-link active'
												: 'nav-link'
										}
									>
										Insurance Company
									</Link>
								)}
								{checkModulePermissions('hospital-management')
									.authorized && (
									<Link
										to={'/hospital'}
										className={
											location.pathname.startsWith(
												'/hospital',
											)
												? 'nav-link active'
												: 'nav-link'
										}
									>
										Hospitals
									</Link>
								)}
								{checkModulePermissions('region-management')
									.authorized && (
									<Link
										to={'/regions'}
										className={
											location.pathname.startsWith(
												'/regions',
											)
												? 'nav-link active'
												: 'nav-link'
										}
									>
										Regions
									</Link>
								)}
								{checkModulePermissions(
									'organization-management',
								).authorized && (
									<Link
										to={'/organization'}
										className={
											location.pathname.startsWith(
												'/organization',
											)
												? 'nav-link active'
												: 'nav-link'
										}
									>
										Organizations
									</Link>
								)}
								{checkModulePermissions('payer-configuration')
									.authorized && (
									<Link
										to={'/payer-configuration'}
										className={
											location.pathname.startsWith(
												'/payer-configuration',
											)
												? 'nav-link active'
												: 'nav-link'
										}
									>
										Payer Configuration
									</Link>
								)}
								{checkModulePermissions('reimbursements')
									.authorized && (
									<Link
										to={'/reimbursements'}
										className={
											location.pathname.startsWith(
												'/reimbursements',
											)
												? 'nav-link active'
												: 'nav-link'
										}
									>
										Reimbursements
									</Link>
								)}
							</Nav>
							<Nav className="justify-content-end flex-grow-2">
								<XDropdown
									menu={{
										items,
									}}
									placement="bottom"
								>
									<XSpace>
										<XAvatar
											size={30}
											src={
												getUserProfile() === 'undefined'
													? '/images/prifile.svg'
													: `${
															config.s3_url
													  }${getUserProfile()}`
											}
										/>
										<XDownOutlined />
									</XSpace>
								</XDropdown>
							</Nav>
						</Offcanvas.Body>
					</Navbar.Offcanvas>
				</XContainer>
			</XNavbar>

			<XUpdateModal
				title="Policies and Codes Updated at"
				centered
				open={updatedDatesModal}
				onCancel={() => {
					setUpdatedDatesModal(false);
					getCodeUpdateDatesReset(); // Reset the state when modal is closed
					setUpdatedDates('');
				}}
				width={650}
				footer={false}
			>
				<Row gutter={(15, 15)}>
					{datesLoading && (
						<Col span={24} style={{ textAlign: 'center' }}>
							<Skeleton active paragraph={{ rows: 3 }} />
						</Col>
					)}
					{updatedDates &&
						updatedDates.map((item) => (
							<Col
								xs={24}
								sm={12}
								md={12}
								lg={12}
								className="my-2"
								key={item._id}
							>
								<div className="modalCard">
									<Row
										gutter={(15, 15)}
										className="align-items-center"
									>
										<Col xs={6}>
											<div className="cardImgBg">
												<img
													src="/images/policies-ncd-icon.svg"
													alt=""
												/>
											</div>
										</Col>
										<Col xs={18}>
											<h4
												style={{
													color: '#212226',
													fontWeight: '600',
												}}
											>
												{item.code_type}
											</h4>
											<h6>
												Last Updated Date -{' '}
												{moment(
													item.latest_update.date,
												).format('DD MMM YYYY')}
											</h6>
											<h6 style={{ fontStyle: '' }}>
												Last Checked Date -{' '}
												{moment(
													item.latest_update
														.updatedAt,
												).format('DD MMM YYYY')}
											</h6>
										</Col>
									</Row>
								</div>
							</Col>
						))}
				</Row>
			</XUpdateModal>
		</div>
	);
};

const mapStateToProps = (state) => ({
	logoutState: state.logout,
	getCodeUpdateDatesState: state.getCodeUpdateDates,
});

const mapDispatchToProps = (dispatch) => ({
	logout: (params) => dispatch(logoutApi(params)),
	logoutReset: () => dispatch(logoutReset()),
	getCodeUpdateDates: () => dispatch(getCodeUpdateDatesApi()),
	getCodeUpdateDatesReset: () => dispatch(getCodeUpdateDatesReset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(HeaderFile);
